import React, { useEffect, useState } from 'react'
import Dashboard from "./pages/Dashboard/index.js"
import { useDispatch } from 'react-redux'
import Chatbot from './pages/Chatbot/index.js'

const App = () => {
  // const [messages,setMessages] = useState(["1"])
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch({type: "CHATBOT_REFERENCE_ID_UPDATE"})
  },[])
  return (
    <div>
      <Chatbot  />
      {/* <h1>{messages}</h1> */}
    </div>
  )
}

export default App