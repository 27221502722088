import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

import BotMessage from "../../components/BotMessage";
import UserMessage from "../../components/UserMessage";
import Messages from "../../components/Messages";
import Input from "../../components/Input";

import { GetChatbotResponse, StorePolicyDetails } from "./ChatbotAPI";

import "./index.css";
import Header from "../../components/Header";
import { connect, useDispatch } from "react-redux";
import {
  dobRegex,
  validationHelper,
  validationRegex,
} from "../../utils/Validation";

const Chatbot = ({
  GetChatbotResponse,
  StorePolicyDetails,
  chatHistory,
  chatbotLoader,
  chatbotForm,
}) => {
  const [formSubmission, setFormSubmission] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    async function loadWelcomeMessage() {
      await GetChatbotResponse("hi");
    }
    loadWelcomeMessage();
  }, []);

  const formSubmissionHandler = async (text) => {
    const {
      chatbotFormResponse,
      chatbotFormStep,
      chatbotFormStatus,
      chatbotFormUserDetails,
    } = chatbotForm;
    dispatch({ type: "CHATBOT_LOADER" });
    dispatch({
      type: "CHATBOT_HISTORY_UPDATE",
      payload: {
        message: [<UserMessage key={chatHistory.length + 1} text={text} />],
      },
    });
    if (text.toLowerCase() === "yes") {
      await StorePolicyDetails();
      setFormSubmission(false);
    } else if (text.toLowerCase() === "no") {
      dispatch({
        type: "CHATBOT_FORM_REQUEST_UPDATE",
        payload: {
          form_response: null,
          step: null,
          topic: null,
          status: false,
          chatbotFormUserDetails: {},
          chatbotSiteDetails: {},
        },
      });
      dispatch({
        type: "CHATBOT_HISTORY_UPDATE",
        payload: {
          message: [<BotMessage chatMessage={"How may I help you?"} />],
        },
      });
      setFormSubmission(false);
    } else {
      const messages = [];
      Object.keys(chatbotFormUserDetails).map((userDetailKey) => {
        messages.push(
          <li>
            {userDetailKey}: {chatbotFormUserDetails[userDetailKey]}
          </li>
        );
      });
      messages.push(
        <li>
          <b>Would you like to create a policy with the above information?</b>
        </li>,
        <li>
          <b>Yes/No</b>
        </li>
      );
      setFormSubmission(true);
      dispatch({
        type: "CHATBOT_HISTORY_UPDATE",
        payload: { message: [<BotMessage chatMessage={messages} />] },
      });
    }
  };

  const chatbotFormRequestDetailsHandler = async (text) => {
    dispatch({ type: "CHATBOT_LOADER" });
    dispatch({
      type: "CHATBOT_HISTORY_UPDATE",
      payload: {
        message: [<UserMessage key={chatHistory.length + 1} text={text} />],
      },
    });

    const ageDetails = [
      "PHO-1226",
      "PHO-1229",
      "PHO-1230",
      "PHO-1231",
      "PHO-1232",
    ];

    const {
      chatbotFormResponse,
      chatbotFormTopic,
      chatbotFormStep,
      chatbotSiteDetails,
      chatbotFormUserDetails,
    } = chatbotForm;

    console.log(chatbotFormTopic);
    console.log(chatbotFormStep);

    if (chatbotFormTopic < chatbotFormResponse.length) {
      const userDetails = chatbotFormUserDetails;

      const topic =
        chatbotFormStep + 1 ===
        chatbotFormResponse[chatbotFormTopic]["attributes"].length
          ? chatbotFormTopic + 1
          : chatbotFormTopic;

      const step =
        chatbotFormStep + 1 ===
        chatbotFormResponse[chatbotFormTopic]["attributes"].length
          ? 0
          : chatbotFormStep + 1;

      const attrCode =
        chatbotFormResponse[chatbotFormTopic]["attributes"][chatbotFormStep][
          "code"
        ];

      if (text !== "") {
        if (attrCode === "PHO-10") {
          if (validationRegex[attrCode].test(text)) {
            chatbotFormResponse[chatbotFormTopic]["attributes"][
              chatbotFormStep
            ]["value"] = new Date(
              text.split("/").reverse().join("-")
            ).toISOString();
          } else {
            dispatch({
              type: "CHATBOT_HISTORY_UPDATE",
              payload: {
                message: [
                  <BotMessage
                    chatMessage={[<li>{validationHelper[attrCode]}</li>]}
                  />,
                ],
              },
            });
            return;
          }
        } else if (
          ageDetails.includes(
            chatbotFormResponse[chatbotFormTopic]["attributes"][
              chatbotFormStep
            ]["code"]
          )
        ) {
          if (
            !Number.isNaN(parseInt(text)) &&
            parseInt(text) - 1 <
              JSON.parse(
                chatbotFormResponse[chatbotFormTopic]["attributes"][
                  chatbotFormStep
                ]["settings"]
              )["items"].length
          ) {
            chatbotFormResponse[chatbotFormTopic]["attributes"][
              chatbotFormStep
            ]["value"] = JSON.parse(
              chatbotFormResponse[chatbotFormTopic]["attributes"][
                chatbotFormStep
              ]["settings"]
            )["items"][parseInt(text) - 1];
          } else {
            dispatch({
              type: "CHATBOT_HISTORY_UPDATE",
              payload: {
                message: [
                  <BotMessage
                    chatMessage={[<li>Incorrect Option selected</li>]}
                  />,
                ],
              },
            });
            return;
          }
        } else {
          if (attrCode in validationRegex) {
            if (validationRegex[attrCode].test(text)) {
              chatbotFormResponse[chatbotFormTopic]["attributes"][
                chatbotFormStep
              ]["value"] = text;
            } else {
              dispatch({
                type: "CHATBOT_HISTORY_UPDATE",
                payload: {
                  message: [
                    <BotMessage
                      chatMessage={[<li>{validationHelper[attrCode]}</li>]}
                    />,
                  ],
                },
              });
              return;
            }
          } else {
            chatbotFormResponse[chatbotFormTopic]["attributes"][
              chatbotFormStep
            ]["value"] = text;
          }
        }
      }

      if (!userDetails[chatbotFormResponse[chatbotFormTopic].name]) {
        userDetails[chatbotFormResponse[chatbotFormTopic].name] = {};
      }
      userDetails[chatbotFormResponse[chatbotFormTopic]["name"]][
        chatbotFormResponse[chatbotFormTopic]["attributes"][chatbotFormStep][
          "name"
        ]
      ] = text;

      dispatch({
        type: "CHATBOT_FORM_REQUEST_UPDATE",
        payload: {
          form_response: chatbotFormResponse,
          topic: topic,
          step: step,
          status: true,
          chatbotFormUserDetails: userDetails,
          siteDetails: chatbotSiteDetails,
        },
      });

      if (topic < chatbotFormResponse.length) {
        dispatch({
          type: "CHATBOT_HISTORY_UPDATE",
          payload: {
            message: [
              <BotMessage
                chatMessage={[
                  <li>
                    <b>
                      Please provide details for{" "}
                      {chatbotFormResponse[topic]["name"]}
                    </b>
                  </li>,
                  <li>
                    {chatbotFormResponse[topic]["attributes"][step]["name"]}
                    {ageDetails.includes(
                      chatbotFormResponse[topic]["attributes"][step]["code"]
                    ) && (
                      <div>
                        {JSON.parse(
                          chatbotFormResponse[topic]["attributes"][step][
                            "settings"
                          ]
                        )["items"].map((productPrice, index) => (
                          <li>
                            {index + 1}. {productPrice}
                          </li>
                        ))}
                        <h5>
                          Please choose the option number you want to proceed
                          with.
                        </h5>
                      </div>
                    )}
                  </li>,
                ]}
              />,
            ],
          },
        });
      } else {
        const messages = [];
        Object.keys(userDetails).map((userDetailKey) => {
          messages.push(
            <li>
              <b>{userDetailKey}</b>
            </li>
          );
          Object.keys(userDetails[userDetailKey]).map((attributeKey) => {
            messages.push(
              <li>
                {attributeKey}: {userDetails[userDetailKey][attributeKey]}
              </li>
            );
          });
        });
        messages.push(
          <li>
            <b>Would you like to create a policy with the above information?</b>
          </li>,
          <li>
            <b>Yes/No</b>
          </li>
        );
        setFormSubmission(true);
        dispatch({
          type: "CHATBOT_HISTORY_UPDATE",
          payload: { message: [<BotMessage chatMessage={messages} />] },
        });
      }
    } else {
      const messages = [];
      Object.keys(chatbotFormUserDetails).map((userDetailKey) => {
        messages.push(
          <li>
            <b>{userDetailKey}</b>
          </li>
        );
        Object.keys(chatbotFormUserDetails[userDetailKey]).map((attribute) => {
          messages.push(
            <li>
              {attribute}: {chatbotFormUserDetails[userDetailKey][attribute]}
            </li>
          );
        });
      });
      messages.push(
        <li>
          <b>Would you like to create a policy with the above information?</b>
        </li>,
        <li>
          <b>Yes/No</b>
        </li>
      );
      setFormSubmission(true);
      dispatch({
        type: "CHATBOT_HISTORY_UPDATE",
        payload: { message: [<BotMessage chatMessage={messages} />] },
      });
    }
  };

  const send = async (text) => {
    dispatch({ type: "CHATBOT_LOADER" });
    dispatch({
      type: "CHATBOT_HISTORY_UPDATE",
      payload: {
        message: [<UserMessage key={chatHistory.length + 1} text={text} />],
      },
    });
    await GetChatbotResponse(text);
  };

  console.log(chatbotForm.chatbotFormResponse);

  return (
    <div className="chatbot">
      <Header />
      <Messages
        messages={[
          ...chatHistory,
          chatbotLoader === true && (
            <BotMessage chatbotLoader={true} chatMessage={[]} />
          ),
        ]}
      />
      <Input
        onSend={
          chatbotForm.chatbotFormStatus === false
            ? send
            : formSubmission === true
            ? formSubmissionHandler
            : chatbotFormRequestDetailsHandler
        }
      />
    </div>
  );
};

const mapPropsToState = (state) => {
  console.log(state?.chatbot?.chatHistory);
  return {
    chatbotLoader: state?.chatbot?.chatbotLoader,
    chatHistory: state?.chatbot?.chatHistory,
    chatbotForm: state?.chatbot?.chatbotForm,
  };
};

export default connect(mapPropsToState, {
  GetChatbotResponse,
  StorePolicyDetails,
})(Chatbot);
