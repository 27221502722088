// dev config variables name
const local = {
  serverUrl: "http://localhost:5006",
  backendUrl: "http://localhost:5001",
  siteName: "brisk-test",
};

// production variables name
const dev = {
  serverUrl: "http://38.242.253.196:5006",
  backendUrl: "http://38.242.253.196:5001",
  siteName: "brisk-test",
};

const prod = {
  serverUrl: "http://161.97.163.253:5006",
  backendUrl: "http://161.97.163.253:5001",
  siteName: "brisk",
};

const config = prod;

// export the default configuration
export default {
  ...config,
};
