import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import {chatbotReducer} from "./reducers/Chatbot.js";
import thunk from 'redux-thunk'

const appReducer = combineReducers( {
    chatbot: chatbotReducer
} );

const rootReducer = ( state, action ) =>
{
	if ( action.type === "USER_LOGOUT" )
	{
		state = undefined;
		return appReducer( undefined, action );
	} else
	{
		return appReducer( state, action );
	}
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
	rootReducer,
	composeEnhancers( applyMiddleware( thunk ) )
);

