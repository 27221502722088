import axios from "axios";
import config from "../../actions/config";
import UserMessage from "../../components/UserMessage";
import BotMessage from "../../components/BotMessage";

const handleOptionSelection = async (option, dispatch, getState) => {
  try {
    dispatch({ type: "CHATBOT_LOADER" });
    const { chatbotSiteDetails, chatbotFormResponse } =
      getState()?.chatbot?.chatbotForm;
    dispatch({
      type: "CHATBOT_SITE_DETAILS_UPDATE",
      payload: {
        siteDetails: {
          ...chatbotSiteDetails,
          productOptionReference: option,
        },
      },
    });
    console.log(chatbotFormResponse);
    const risks = chatbotFormResponse.map((risk) => {
      if (risk["code"] === "PHO") {
        const productOptionDetail = chatbotSiteDetails.productOptions.find(
          (productOption) => productOption.productOptionReference === option
        );
        return {
          ...risk,
          ...productOptionDetail.rating,
          externalReference: "",
        };
      } else {
        return {
          ...risk,
          price: 0,
          discount: 0,
          tax: 0,
          total: 0,
          externalReference: "",
        };
      }
    });

    console.log(risks);
    axios
      .post(
        `${config.backendUrl}/policy/create`,
        {
          risks: risks,
          productOptionReference: option,
          channelProductId: chatbotSiteDetails["channelProductId"],
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          const messages = [];

          const data = JSON.parse(res.data);
          console.log(data);
          dispatch({
            type: "CHATBOT_HISTORY_UPDATE",
            payload: {
              message: [
                <BotMessage
                  chatMessage={[
                    <b>Policy created Successfully.</b>,
                    <li>
                      Your reference Id is{" "}
                      <b>{data["policies"][0]["policyReference"]}</b>
                    </li>,
                  ]}
                />,
              ],
            },
          });
          dispatch({
            type: "CHATBOT_FORM_REQUEST_UPDATE",
            payload: {
              form_response: null,
              step: null,
              status: false,
              chatbotFormUserDetails: {},
              chatbotSiteDetails: {},
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: "CHATBOT_HISTORY_UPDATE",
          payload: {
            message: [
              <BotMessage
                chatMessage={[
                  "Please try creating a policy again. Sorry for the inconvenience",
                ]}
              />,
            ],
          },
        });
        dispatch({
          type: "CHATBOT_FORM_REQUEST_UPDATE",
          payload: {
            form_response: null,
            step: null,
            status: false,
            chatbotFormUserDetails: {},
            chatbotSiteDetails: {},
          },
        });
      });
  } catch (error) {
    console.error("Error creating policy:", error);
    // Handle errors appropriately, such as displaying an error message
  }
};

export const GetChatbotResponse = (message) => async (dispatch, getState) => {
  dispatch({ type: "CHATBOT_LOADER" });
  const chatHistory = getState()?.chatbot?.chatHistory;
  console.log(message);
  axios
    .post(
      `${config.serverUrl}/webhooks/rest/webhook`,
      {
        sender: "test_user",
        message: message,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      console.log(res);
      // console.log(res.data[0]["custom"]&&["form_flag"])
      if (
        res.data[0]["custom"] !== undefined &&
        res.data[0]["custom"]["form_flag"]
      ) {
        console.log(res.data[0]["custom"].form_response);
        console.log(res.data[0]["custom"].form_response["error"]);
        if (res.data[0]["custom"].form_response["error"] === undefined) {
          console.log(typeof res.data[0]["custom"].form_response);
          const result = res.data[0]["custom"].form_response;
          const form_response = [];
          const topics = [...result[0]["risks"]];
          topics.map((topic) => {
            console.log(topic);
            if (topic["minCount"] == 1) {
              topic.attributes = topic.attributes.filter(
                (attribute) =>
                  attribute.isRequired === true || attribute.quickQuote === true
              );
              topic.attributes = topic.attributes.map((attribute) => {
                const { dataType, code, name, settings } = attribute;
                return { dataType, code, name, settings };
              });
              topic.productRiskReference = topic.instanceId;
              form_response.push(topic);
            }
          });
          dispatch({
            type: "CHATBOT_FORM_REQUEST_UPDATE",
            payload: {
              form_response: form_response,
              topic: 0,
              step: 0,
              status: true,
              chatbotFormUserDetails: {},
              siteDetails: {
                channelProductId: result[0]["product"]["instanceId"],
              },
            },
          });
          const chatbotFormResponse =
            getState()?.chatbot?.chatbotForm?.chatbotFormResponse;
          const { chatbotFormStep, chatbotFormTopic } =
            getState()?.chatbot?.chatbotForm;
          const messages = [
            <BotMessage
              chatMessage={[
                <li>
                  <b>
                    Please provide details for{" "}
                    {chatbotFormResponse[chatbotFormTopic]["name"]}
                  </b>
                </li>,
                <li>
                  {
                    chatbotFormResponse[chatbotFormTopic]["attributes"][
                      chatbotFormStep
                    ]["name"]
                  }
                </li>,
              ]}
            />,
          ];
          dispatch({
            type: "CHATBOT_HISTORY_UPDATE",
            payload: { message: messages },
          });
        }
      } else {
        console.log("Else condition");
        const botMessages = [];
        res.data.map((chatbotResponse) => {
          botMessages.push(<li>{chatbotResponse.text}</li>);
        });
        const messages = [<BotMessage chatMessage={botMessages} />];
        dispatch({
          type: "CHATBOT_HISTORY_UPDATE",
          payload: { message: messages },
        });
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const StorePolicyDetails = () => async (dispatch, getState) => {
  dispatch({ type: "CHATBOT_LOADER" });
  const chatbotSiteDetails =
    getState()?.chatbot?.chatbotForm?.chatbotSiteDetails;
  const policyDetails = getState()?.chatbot?.chatbotForm?.chatbotFormResponse;
  console.log(policyDetails);

  const body = {
    executeCalculatorRequest: {
      risks: policyDetails,
      factors: [],
      productFrequencies: [],
    },
    productTypeReferences: ["83c94d80-402b-2083-99b6-c130d406f6fb"],
    calculatorVersion: 1,
    collectionType: 3,
    isProductPaymentAvailable: 1,
  };
  axios
    .post(`${config.backendUrl}/policy-details`, body, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      console.log(res);
      if (res.status === 200) {
        const messages = [];
        dispatch({
          type: "CHATBOT_SITE_DETAILS_UPDATE",
          payload: {
            siteDetails: {
              ...chatbotSiteDetails,
              productOptions: res.data.productOptions,
            },
          },
        });
        messages.push(<>Select Product Option</>);
        res.data.productOptions.map((productOption, index) => {
          messages.push(
            <div>
              <b>{productOption.name}</b>
              <button
                key={index}
                onClick={() =>
                  handleOptionSelection(
                    productOption.productOptionReference,
                    dispatch,
                    getState
                  )
                }
                style={{
                  margin: "5px",
                  borderRadius: "5px",
                  backgroundColor: "white",
                  padding: "5px",
                }}
              >
                R{productOption.rating.price} - {productOption.rating.frequency}
              </button>
            </div>
          );
        });

        dispatch({
          type: "CHATBOT_HISTORY_UPDATE",
          payload: { message: [<BotMessage chatMessage={messages} />] },
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: "CHATBOT_HISTORY_UPDATE",
        payload: {
          message: [
            <BotMessage
              chatMessage={[
                "Please try creating a policy again. Sorry for the inconvenience",
              ]}
            />,
          ],
        },
      });
      dispatch({
        type: "CHATBOT_FORM_REQUEST_UPDATE",
        payload: {
          form_response: null,
          step: null,
          status: false,
          chatbotFormUserDetails: {},
          chatbotSiteDetails: {},
        },
      });
    });
};
