import { v4 as uuidv4 } from "uuid";

const INITIAL_STATE = {
  chatHistory: [],
  chatbotError: null,
  chatbotLoader: false,
  chatbotRefId: null,
  chatbotForm: {
    chatbotFormResponse: null,
    chatbotFormStep: null,
    chatbotFormTopic: null,
    chatbotFormStatus: false,
    chatbotFormUserDetails: {},
    chatbotSiteDetails: {},
  },
};

export const chatbotReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "CHATBOT_HISTORY_UPDATE":
      console.log("action.payload>>>", action.payload);
      return {
        ...state,
        chatHistory: [...state.chatHistory, ...action.payload.message],
        chatbotError: null,
        chatbotLoader: false,
      };
    case "CHATBOT_REFERENCE_ID_UPDATE":
      return {
        ...state,
        chatbotRefId: uuidv4(),
      };
    case "CHATBOT_FORM_REQUEST_UPDATE":
      return {
        ...state,
        chatbotForm: {
          chatbotFormResponse: action.payload.form_response,
          chatbotFormStep: action.payload.step,
          chatbotFormTopic: action.payload.topic,
          chatbotFormStatus: action.payload.status,
          chatbotFormUserDetails: action.payload.chatbotFormUserDetails,
          chatbotSiteDetails: action.payload.siteDetails,
        },
      };
    case "CHATBOT_FORM_USER_DETAILS_UPDATE":
      console.log(
        "action payload user details",
        action.payload.chatbotFormUserDetails
      );
      return {
        ...state,
        chatbotForm: {
          ...state?.chatbotForm,
          chatbotFormUserDetails: action.payload.chatbotFormUserDetails,
        },
      };
    case "CHATBOT_SITE_DETAILS_UPDATE":
      return {
        ...state,
        chatbotForm: {
          ...state?.chatbotForm,
          chatbotSiteDetails: action.payload.siteDetails,
        },
      };
    case "CHATBOT_LOADER":
      return {
        ...state,
        chatbotLoader: true,
        chatbotError: null,
      };
    case "CHATBOT_ERROR":
      return {
        ...state,
        chatbotError: action.payload.error,
        chatbotLoader: false,
      };
    default:
      return state;
  }
};
