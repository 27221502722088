import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

const BotMessage = ({ chatMessage=[] ,chatbotLoader=false}) => {
  const [message, setMessage] = useState([]);

  useEffect(() => {
    if(chatMessage.length>0){
      setMessage(chatMessage);
    }
  }, [chatMessage]);

  return (
    <div className="message-container">
      <div className="bot-message">{chatbotLoader&&message.length==0 ? "..." : <ul>{message}</ul>}</div>
    </div>
  );
}


export default BotMessage;
